.custom-button {
  color: #b7250c;
  border-color: #b7250c;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease; /* Renk geçişi eklendi */
}

.custom-button:hover {
  background-color: #b7250c;
  border-color: #b7250c;
  color: #fff; /* Hover durumunda metin rengini değiştir */
}

.custom-button:active {
  background-color: white !important; /* Tıklama durumunda arka plan rengini değiştir */
  border-color: #b7250c !important;
  color: #b7250c !important; /* Tıklama durumunda metin rengini değiştir */
}

.button-text {
  margin-left: 5px;
  transition: color 0.3s ease;
}

.red-main-button {
  color: white;
  background: #b7250c;
  border: none;
}

.red-main-button:hover {
  background-color: #b7250c;
}
.red-main-button:active {
  background-color: #b7250c !important;
}
