.image-fixed {
  width: 100%;
  height: 500px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: black; /* Change arrow color to black */
}
.EducationShortInfoContainer {
  background-color: #201e1e;
}
.footerContainer {
  color: white;
  background-color: #b7250c;
}
.footerListStyle {
  list-style-type: square;
}
.footerListStyle a {
  text-decoration: none;
}
.footerListStyle li {
  color: #fff;
  border: none;
}
.footerListStyle li:hover {
  color: rgb(110, 110, 221);
}
.ContainerDesign {
  border-radius: 16px;
  background: whitesmoke;
}
.navbarTitle {
  font-weight: 600;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  background-size: 100%, 100%;
  border-radius: 16px;
}

.exam-header {
  cursor: pointer;
}

.exam-header:hover + .exam-buttons {
  display: block;
}

@keyframes colorChange {
  0% {
    fill: red;
  }
  50% {
    fill: green;
  }
  100% {
    fill: darkblue;
  }
}

.chevron-icon {
  animation: colorChange 4s infinite;
  height: 60px;
  width: 40px;
}
